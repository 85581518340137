<template>
  <div class="chessHand"
       style="padding-bottom: 20px">
    <div v-title
         data-title="全民代理"></div>

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-Nationalagency.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">{{ datalist.date }}</span>
            </div>
            <div class="title-item">
              <p>参与方式:</p>
              <span style="color:var(--li-colortext);">有效流水<span style="color:#418BEB;">≥1000，每月≥1位有效会员</span>即可获得返佣</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>

          <p style="text-align:center;margin-top: 10px;color: #333;">您的有效流水：<span style="color: #418BEB;">{{
            datalist.dpt_limit }}</span></p>

          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;font-size: 12px;">
                  每月有效
                  <p style="margin-top: 5px;">会员人员</p>
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;font-size: 12px;">
                  返佣比例
                  <p style="margin-top: 5px;">(负盈利)</p>
                </th>
                <th style="color: #fff; border-top-right-radius: 10px;font-size: 12px;">
                  返佣时间
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.config"
                  :key="index">
                <td>≥{{ item.dpt_money }}</td>
                <td>{{ item.dpt_bouns }}%</td>
                <td>每月6号</td>
              </tr>
              <tr>
                <td :colspan="3"
                    style="font-size: 13px;color: #333;">
                  注：<span style="color:#418BEB;">您的有效流水≥1000</span>才可开启全民代理的功能
                </td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">我要申请全民代理</div>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                有效流水≥1000即可开启全民代理的功能，并且每月至少有1位有效会员才可获得负盈利的返佣。
              </li>
              <li>
                <span>2.</span>
                佣金发放时间：每月6日24小时内系统自动发放。
              </li>
              <li>
                <span>3.</span>
                存款≥500或有效流水≥1000即为有效会员。
              </li>
              <li>
                <span>4.</span>
                会员实际负盈利=会员输赢-彩金红利-返水-优惠。
              </li>
              <li>
                <span>5.</span>
                每月≥1位有效会员领取负盈利的8%，≥3位有效会员领取负盈利的10%，≥5位有效会员领取负盈利的15%，每月6日24小时内系统自动结算上月负盈利。<br />
                计算公式如下：<br />
                例如：<br />
                (1)A会员邀请B会员，B会员当月输了10,000元，隔月6日结算A会员可获得返佣8%，<br />即为：10,000×8%=800元。<br />
                由此类推，如果A会员邀请B、C、D会员，B、C、D会员当一共输了20,000，隔月6日A会员可获得返佣10%，<br />即为：20,000×10%=2000元。<br />
                (2)A会员邀请B会员，B会员当月赢了10,000元，A会员没有返佣。
              </li>
              <li>
                <span>6.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息。
              </li>
              <li>
                <span>7.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>8.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>9.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>
              <li>
                <span>10.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker title="请选择场馆"
                  show-toolbar
                  value-key="name"
                  :columns="datalist.youxi"
                  @confirm="confirm"
                  @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      aa: { title: 'uni' },
      cueTheme: '',
      showPicker: false,
    }
  },
  onShow() { },

  mounted() {
    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },

  methods: {
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },

    getdata() {
      var _this = this
      var data = '?type=dl_member_agent'
      getdata(data).then((res) => {
        this.datalist = res.message
      })
    },
    changeisshow() {
      clearInterval(this.timer)
      this.isshow = true
      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay() {
      this.showPicker = true

    },
    confirm(e) {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'dl_member_agent',
                  gameid: e.id
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          uni.postMessage({
            data: {
              urlApply: 'vipActapply',
              actname: 'dl_member_agent',
              gameid: e.id
            }
          })
          // 申请
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'dl_member_agent',
          //       gameid: e.id
          //     },
          //   }
          // })
        }
      })

      this.showPicker = false
    },
    cancel() {
      this.showPicker = false
    }
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.chessHand {
  position: relative;
  font-family: PingFang SC;

  .btn-box {
    padding-bottom: 5px;

    > div {
      font-family: PingFang SC;
      font-weight: 400;

      &:nth-child(1) {
        height: 40px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(0deg, #2a6afd, #63c6ff);
        margin: 25px 0px 15px 0px;
      }

      &:nth-child(2) {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #2a6afd;
        color: #418beb;
        margin: 15px 0px 15px 0px;
      }
    }
  }

  .rightbox {
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 1;
    background: linear-gradient(0deg, #2a6afd, #63c6ff);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 5px 5px 5px 0;
    color: #fff;

    .content {
      display: flex;
      align-items: center;

      > div {
        &:nth-child(1) {
          min-width: 54px;
          height: 54px;
          border: 4px solid #fff;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }

        .primary_color {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
</style>
